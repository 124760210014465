@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Inter', sans-serif;
}

@layer components {
  /* .label {
    @apply block p-1 whitespace-nowrap text-left text-xs font-semibold uppercase tracking-wide;
  } */

  .btn {
    @apply relative flex justify-center py-3 px-3 border border-transparent text-sm font-medium rounded-md text-white bg-black cursor-pointer hover:bg-gray-800 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-gray-800;
  }

  a {
    @apply font-medium text-blue-600 underline hover:text-black;
  }

  .btn-delete {
    @apply inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white items-center bg-red-600 hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 cursor-pointer;
  }

  /* Sidebar */
  .sidebar-item {
    @apply inline-flex items-center w-full px-4 py-2 mb-3 text-sm text-white transition duration-300 ease-in-out transform rounded-md  bg-gray-400 border-white cursor-pointer no-underline hover:text-white hover:bg-gray-800;
  }

  .sidebar-item-active {
    @apply inline-flex items-center w-full px-4 py-2 mb-3 text-sm text-black transition duration-300 ease-in-out transform rounded-md bg-orange cursor-default no-underline;
  }

  .sidebar-item > .sidebar-item-active-arrow {
    visibility: hidden;
  }

  .sidebar-item-active > .sidebar-item-active-arrow {
    visibility: visible;
  }

  .form-section {
    @apply space-y-2 bg-gray-100 p-4 rounded-md w-full;
  }
}
